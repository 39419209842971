<template>
    <div class="wrapper">
        <div class="dataBox grid-container" :class="[screenWidth > 1000 ? '' : 'grid-container2']">
            <div class="box1 flex itemOne" :class="[screenWidth > 1000 ? 'box01' : 'box02']">
                <img class="img" src="../../assets/img/stack/data01.png" alt="" />
                <p class="flex-column flex-1">
                    <span style="font-size: 18px">{{ totalPower }}</span>
                    <span style="font-size: 14px; color: #999999">{{lang=='en'?'Computing power of the whole network':'全网算力'}}</span>
                </p>
            </div>
            <div class="box1 flex-1 flex itemOne" :class="[screenWidth > 1000 ? '' : 'box02']">
                <img class="img" src="../../assets/img/stack/data02.png" alt="" />
                <p class="flex-column flex-1">
                    <span style="font-size: 18px">{{ $integer($fromWei(totalInPower, 18), 6) }}</span>
                    <span style="font-size: 14px; color: #999999">{{lang=='en'?'Personal cumulative computing power':'个人累计算力'}}</span>
                </p>
            </div>
            <div class="box1 flex-1 flex itemOne" :class="[screenWidth > 1000 ? '' : 'box02']">
                <img class="img" src="../../assets/img/stack/data03.png" alt="" />
                <p class="flex-column flex-1">
                    <span style="font-size: 18px">
                        {{ $integer(mytotal.outedpower, 6) }}
                        <em>= {{ $integer(mytotal.outedpower * 2, 6) }}U</em>
                    </span>
                    <span style="font-size: 14px; color: #999999">{{lang=='en'?'Hashrate is out':'已出局算力'}}</span>
                </p>
            </div>

            <div class="box1 flex-1 flex itemOne" :class="[screenWidth > 1000 ? '' : 'box02']">
                <img class="img" src="../../assets/img/stack/data04.png" alt="" />
                <p class="flex-column flex-1">
                    <span style="font-size: 18px">
                        {{ $integer($fromWei(selePower, 18), 6) }}
                        <em>= {{ $integer($fromWei(selePower, 18) * 2, 6) }}U</em>
                    </span>
                    <span style="font-size: 14px; color: #999999">{{lang=='en'?'Personal effective computing power':'个人有效算力'}}</span>
                </p>
            </div>
            <div class="box1 flex-1 flex itemOne" :class="[screenWidth > 1000 ? '' : 'box02']">
                <img class="img" src="../../assets/img/stack/data05.png" alt="" />
                <p class="flex-column flex-1">
                    <span style="font-size: 18px">{{ $integer($fromWei(canBuyPower, 18), 6) }}</span>
                    <span style="font-size: 14px; color: #999999">{{lang=='en'?'Hashrate can be added to the order':'可入单算力'}}</span>
                </p>
            </div>
        </div>
        <div class="stackMain box1" :class="[screenWidth > 1000 ? '' : 'box02 stackMain2']">
            <p class="flex aaa">
                <img src="../../assets/img/stack/amount.png" alt="" />
                <span>{{lang=='en'?'Enter the hashrate of the order':'输入入单算力'}}</span>
                <a-tooltip>
                    <template #title>{{lang=='en'?'1U=1 hashrate twice the value is out':'1U=1算力  两倍价值出局'}}</template>
                    <img style="width: 20px;height: 20px;margin-left: 8px;" src="../../assets/img/default/wenhao.png" alt="">
                </a-tooltip>
            </p>
            <div>
                <input :placeholder="lang=='en'?'Enter the quantity':'输入数量'" v-model="amount" type="text" />
                <span style="cursor: pointer" @click="amount = $integer($fromWei(canBuyPower, 18), 6)">MAX</span>
            </div>
            <span class="flex balance">
                <span>{{lang=='en'?'Balance:':'余额：'}}</span>
                <span>{{ $fromWei(usdtBalance, 18) }} USDT</span>
            </span>
            <div class="btnBox flex-column" :class="[screenWidth > 1000 ? '' : 'btnBox2']">
                <p class="flex jc-center">{{lang=='en'?'*Deposit rules: up to 3000 computing power per person':'*入单规则：每个人最多3000算力'}}</p>
                <Mbutton :value="btnText" @click="submitFn" :loading="btnLoading" />
            </div>
        </div>
    </div>
</template>

<script>
import { balanceOf, getBlockTimestamp, allowance2, approve } from '@/web3utils/base/index.js';
import { BuyOrder, totalInPower } from '@/web3utils/stack/index.js';
import Mbutton from '@/components/Mbutton.vue';
import { teamtotal, getTeamAllPower, mytotal,Balance } from '@/api/Users.js';
import { mapState } from 'vuex';
export default {
    name: 'stackContent',
    data() {
        return {
            amount: '',
            btnLoading: false,
            usdtBalance: 0,
            tokenAllowance: 0,
            mytotal: {}, // 已出局算力： mytotal的outedpower
            totalPower: 0, // 全网算力
            totalInPower: 0, // 累计算力
            selePower: 0, // 个人有效算力  // 累计算力 – 已出局算力
            canBuyPower: 0, // 可入单算力   3000 - 有效算力
        };
    },

    components: { Mbutton },

    computed: {
        ...mapState(['screenWidth', 'config', 'address', 'token','lang']),
        usdtAddress() {
            return this.config[304].val;
        },
        stackContractAddress() {
            return this.config[305].val;
        },
        btnText() {
            if (!Number(this.amount)) return this.lang=='en'?'Please enter the hashrate for the order':'请输入入单算力';
            if (!Number(this.tokenAllowance) || BigInt(this.tokenAllowance) < BigInt(this.$toWei(this.amount, 18))) {
                return this.lang=='en'?'USDT authorized':'授权USDT';
            }
            if (BigInt(this.$toWei(this.amount, 18)) > BigInt(this.usdtBalance)) return  this.lang=='en'?'The balance is insufficient':'余额不足';
            if( BigInt(this.canBuyPower) < BigInt(this.$toWei(this.amount,18))) return this.lang=='en'?'Exceeds the maximum hashrate that can be purchased':'超过最大可入单算力';
            return this.lang=='en'?'Submit':'提交';
        },
    },

    async mounted() {
        if (this.address && this.token) this.initFn();
    },
    methods: {
        async initFn() {
            this.totalPower = (await getTeamAllPower()).data;
            this.totalInPower = await totalInPower();
            await this.checkToekn();
            await Balance()
            this.mytotal = (await mytotal()).data;
            this.usdtBalance = await balanceOf(this.usdtAddress);
            this.selePower = BigInt(this.totalInPower) - BigInt(this.$toWei(this.mytotal.outedpower, 18));
            this.canBuyPower = BigInt(this.$toWei(3000, 18)) - this.selePower;
        },
        async checkToekn() {
            this.tokenAllowance = await allowance2(this.usdtAddress, this.stackContractAddress);
        },
        async submitFn() {
            if (!Number(this.amount)) return
            this.btnLoading = true;
            if (!Number(this.tokenAllowance) || BigInt(this.tokenAllowance) < BigInt(this.$toWei(this.amount, 18))) {
                let res = await approve(this.usdtAddress, this.stackContractAddress);
                if (res.code) {
                    await this.checkToekn();
                }
                this.btnLoading = false;
                return;
            }
            this.btnLoading = false;
            if (BigInt(this.$toWei(this.amount,18)) > BigInt(this.usdtBalance)) return console.log('余额不足')
            if( BigInt(this.canBuyPower) < BigInt(this.$toWei(this.amount,18))) return;
            this.btnLoading = true;
            let res2 = await BuyOrder(this.$toWei(this.amount, 18), this.$toWei(this.mytotal.outedpower, 18));
            console.log('res2===>', res2);
            this.btnLoading = false;
            if (res2.code) this.initFn();
        },
    },
};
</script>
<style lang="scss" scoped>
.wrapper {
    width: 100%;
    .dataBox {
        width: 100%;
        .img {
            width: 60px;
            height: 60px;
        }
        .itemOne {
            & > p {
                margin-bottom: 0px;
                padding-left: 20px;
                & > span:nth-of-type(1) {
                    em {
                        font-style: normal;
                        font-size: 12px;
                    }
                }
            }
        }
    }
}
.grid-container2 {
    grid-template-columns: repeat(1, 1fr) !important;
    column-gap: 10px !important;
    row-gap: 10px !important;
}
.grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    row-gap: 20px;
}
.box01 {
    grid-column: 1 / 3;
    // grid-row: 1 / 3;
}
.box02 {
    padding: 10px 16px !important;
    &>img{
        width: 50px;
        height: 50px;
    }
    & > p {
        padding-left: 12px !important;
    }
    .aaa {
        margin-top: 10px;
        padding-left: 0px !important;
        span {
            font-size: 16px !important;
        }
    }
}
.stackMain2{
    margin-top: 20px !important;
    padding: 20px 30px !important;
}
.stackMain {
    padding: 40px 30px;
    margin-top: 40px;
    & > p:nth-of-type(1) {
        img {
            width: 24px;
            height: 24px;
            margin-right: 8px;
        }
        span {
            font-size: 16px;
            font-weight: 600;
        }
    }
    & > div:nth-of-type(1) {
        display: flex;
        input {
            flex: 1;
            height: 50px;
            box-sizing: border-box;
            padding: 10px 20px;
            background: #29292d;
            font-size: 18px;
            border-radius: 10px;
        }
        input::placeholder{
            font-size: 16px !important;
        }
        span {
            margin-left: 16px;
            width: 60px;
            background: #29292d;
            border-radius: 10px;
            text-align: center;
            line-height: 50px;
        }
    }
    .balance {
        padding-top: 20px;
    }
    .btnBox {
        justify-content: center;
        align-items: center;
        padding: 80px 0px 20px;
        & > p {
            font-size: 14px;
            color: #98989b;
        }
        .mbutton {
            width: 100% !important;
        }
    }
    .btnBox2 {
        padding-top: 20px !important;
    }
}
</style>
